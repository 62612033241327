.expandable-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
}

@media (max-width: 768px) {
  .expandable-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
  }
}
