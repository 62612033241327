/* App.css */
:root {
  --media-height: 316px;
}

.custom-card {
  @apply w-[340px];
  /* width: 304px; */
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.w-160px {
  @apply w-[160px];
}
.w-200px {
  @apply w-[200px];
}
.w-212px {
  @apply w-[212px];
}
.w-216px {
  @apply w-[216px];
}
.w-268px {
  @apply w-[268px];
}
.w-292px {
  @apply w-[292px];
}
.w-324px {
  @apply w-[324px];
}
.w-328px {
  @apply w-[328px];
}
.w-340px {
  @apply w-[340px];
}
.w-328px {
  @apply w-[328px];
}
.w-436px {
  @apply w-[436px];
}
.w-444px {
  @apply w-[444px];
}
.w-520px {
  @apply w-[520px];
}
.w-556px {
  @apply w-[556px];
}
.w-628px {
  @apply w-[628px];
}

.h-160px {
  @apply h-[160px];
}
.h-200px {
  @apply h-[200px];
}
.h-212px {
  @apply h-[212px];
}
.h-216px {
  @apply h-[216px];
}
.h-268px {
  @apply h-[268px];
}
.h-280px {
  @apply h-[280px];
}
.h-292px {
  @apply h-[292px];
}
.h-324px {
  @apply h-[324px];
}
.h-340px {
  @apply h-[340px];
}
.h-328px {
  @apply h-[328px];
}
.h-436px {
  @apply h-[436px];
}
.h-444px {
  @apply h-[444px];
}
.h-468px {
  @apply h-[468px];
}
.h-520px {
  @apply h-[520px];
}
.h-556px {
  @apply h-[556px];
}
.h-628px {
  @apply h-[628px];
}
.h-664px {
  @apply h-[664px];
}

.media-container-parent {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
}

.media-container {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.media-container.fade {
  opacity: 0.5;
}

.nav-container:hover .prev-button,
.nav-container:hover .next-button {
  display: block;
}

.main-media {
  /* height: var(--media-height); */
  width: "100%";
  object-fit: cover;
}

.prev-button,
.next-button {
  display: none;
  position: absolute;
  /* top: 50%; */
  top: 0%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0.7;
  border-radius: 50%;
  border: 0px;
}

.prev-button {
  left: 0px;
}

.next-button {
  right: 0px;
}

.thumbnail {
  width: 24%;
  cursor: pointer;
  opacity: 0.7;
}

.thumbnail.active,
.thumbnail:hover {
  opacity: 1;
}

.details-container {
  /* margin-top: var(--media-height); */
}

.details-text {
  margin-bottom: 10px;
  height: 40px;
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

.price-container {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.price {
  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
}

@media (max-width: 1919px) {
  .price {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }
}
@media (max-width: 1365px) {
  .price {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }
}

@media (max-width: 768px) {
  .price-show-add-mobile {
    font-size: 12px;
    font-weight: 700;
    line-height: 23px;
  }
}

.red-price {
  color: red;
  font-weight: bold;
}

.additional-info {
  display: flex;
  justify-content: space-between;
}

/* .skeleton-image {
  width: 304px !important;
} */
